// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-1-mdx": () => import("./../../../src/pages/issues/1.mdx" /* webpackChunkName: "component---src-pages-issues-1-mdx" */),
  "component---src-pages-issues-10-mdx": () => import("./../../../src/pages/issues/10.mdx" /* webpackChunkName: "component---src-pages-issues-10-mdx" */),
  "component---src-pages-issues-11-mdx": () => import("./../../../src/pages/issues/11.mdx" /* webpackChunkName: "component---src-pages-issues-11-mdx" */),
  "component---src-pages-issues-2-mdx": () => import("./../../../src/pages/issues/2.mdx" /* webpackChunkName: "component---src-pages-issues-2-mdx" */),
  "component---src-pages-issues-3-mdx": () => import("./../../../src/pages/issues/3.mdx" /* webpackChunkName: "component---src-pages-issues-3-mdx" */),
  "component---src-pages-issues-4-mdx": () => import("./../../../src/pages/issues/4.mdx" /* webpackChunkName: "component---src-pages-issues-4-mdx" */),
  "component---src-pages-issues-5-mdx": () => import("./../../../src/pages/issues/5.mdx" /* webpackChunkName: "component---src-pages-issues-5-mdx" */),
  "component---src-pages-issues-6-mdx": () => import("./../../../src/pages/issues/6.mdx" /* webpackChunkName: "component---src-pages-issues-6-mdx" */),
  "component---src-pages-issues-7-mdx": () => import("./../../../src/pages/issues/7.mdx" /* webpackChunkName: "component---src-pages-issues-7-mdx" */),
  "component---src-pages-issues-8-mdx": () => import("./../../../src/pages/issues/8.mdx" /* webpackChunkName: "component---src-pages-issues-8-mdx" */),
  "component---src-pages-issues-9-mdx": () => import("./../../../src/pages/issues/9.mdx" /* webpackChunkName: "component---src-pages-issues-9-mdx" */),
  "component---src-pages-issues-markdown-mdx": () => import("./../../../src/pages/issues/markdown.mdx" /* webpackChunkName: "component---src-pages-issues-markdown-mdx" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

